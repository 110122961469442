import React, { FC } from 'react';
import {
  TextButton,
  TEXT_BUTTON_PRIORITY as TextButtonPriority,
} from 'wix-ui-tpa/TextButton';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { DataHook } from '../../../../../types';
import {
  useBlogPosts,
  useCanEditMember,
} from '../../../../../contexts/widgets';
import settingsParams from '../../../settingsParams';
import SectionTitle from '../../common/SectionTitle';
import { useCreatePostClickHandler } from '../hooks';
import { classes, st } from './Title.st.css';

export const Title: FC = () => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { canEditMember } = useCanEditMember();
  const { createBlogPostUrl } = useBlogPosts();
  const { handleCreatePostLinkClick } = useCreatePostClickHandler();

  return (
    <div className={classes.root}>
      <SectionTitle>{settings.get(settingsParams.blogPostsTitle)}</SectionTitle>
      {canEditMember && (
        <TextButton
          data-hook={DataHook.CreatePostLink}
          className={st(classes.createPostLink, { mobile: isMobile })}
          as="a"
          href={createBlogPostUrl?.url}
          priority={TextButtonPriority.primary}
          onClick={handleCreatePostLinkClick}
        >
          {t('recent-posts.create-post')}
        </TextButton>
      )}
    </div>
  );
};
