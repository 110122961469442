import React, { FC } from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { Tooltip } from 'wix-ui-tpa/Tooltip';

import { DataHook } from '../../../../../types';
import { classes, st } from './StatisticalUnit.st.css';

export interface StatisticalUnitProps {
  className: string;
  dataHook: DataHook;
  titleSingular: string;
  titlePlural: string;
  tooltipText: string;
  count: number;
  isMobile: boolean;
}

const thousand = 1000;
const million = Math.pow(thousand, 2);
const billion = Math.pow(thousand, 3);

const toPrettyNumber = (value: number) => {
  if (value >= billion) {
    return `${Math.floor(value / billion)}B`;
  } else if (value >= million) {
    return `${Math.floor(value / million)}M`;
  } else if (value >= thousand) {
    return `${Math.floor(value / thousand)}k`;
  }

  return value;
};

export const StatisticalUnit: FC<StatisticalUnitProps> = ({
  className,
  dataHook,
  titleSingular,
  titlePlural,
  tooltipText,
  count,
  isMobile,
}) => {
  return (
    <Tooltip
      className={className}
      content={tooltipText}
      placement="top-start"
      minWidth={200}
    >
      <Text
        data-hook={dataHook}
        className={st(classes.content, { mobile: isMobile })}
      >
        <span>{toPrettyNumber(count)}</span>
        &nbsp;
        <span className={classes.title}>
          {count > 1 ? titlePlural : titleSingular}
        </span>
      </Text>
    </Tooltip>
  );
};
